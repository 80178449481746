<!--
 * @Description:底部我的-关于我们-隐私政策 privacyPolicy
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-27 15:19:07
 * @LastEditors: linf
-->
<!--  -->
<template>
  <div class='main'>
    <van-nav-bar title="隐私政策" />
    <div style="padding:0 10px">
      <h1 style="text-align:center">《APP隐私政策》</h1>
      <div style="text-indent:2em">
        本应用非常重视用户隐私政策并严格遵守相关的法律规定。请您仔细阅读《隐私政策》后再继续使用。如果您继续使用我们的服务，表示您已经充分阅读和理解我们协议的全部内容。<br />
      </div>
      <div style="text-indent:2em">
        本app尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更优质的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。<br />
      </div>
      <div style="text-indent:2em">
        1. 适用范围<br />
      </div>
      <div style="text-indent:2em">
        (a)在您注册本应用app帐号时，您根据app要求提供的个人注册信息；<br />
      </div>
      <div style="text-indent:2em">
        (b)在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；<br />
      </div>
      <div style="text-indent:2em">
        (c)本应用通过合法途径从商业伙伴处取得的用户个人数据。<br />
      </div>
      <div style="text-indent:2em">
        (d)本应用严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限，予以封号处理。<br />
      </div>
      <div style="text-indent:2em">
        2. 信息使用<br />
      </div>
      <div style="text-indent:2em">
        (a)本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许本应用消息通知。<br />
      </div>
      <div style="text-indent:2em">
        (b)本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。<br />
      </div>
      <div style="text-indent:2em">
        (c)为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。<br />
      </div>
      <div style="text-indent:2em">
        (d) 您使用服务时我们可能收集如下信息：<br />
      </div>
      <div style="text-indent:2em">
        1、日志信息，指您使用我们的服务时，系统可能通过cookies、webbeacon或其他方式自动采集的技术信息，包括：<br />
      </div>
      <div style="text-indent:2em">
        1)设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码等；在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；<br />
      </div>
      <div style="text-indent:2em">
        2）有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；<br />
      </div>
      <div style="text-indent:2em">
        3）您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；<br />
      </div>
      <div style="text-indent:2em">
        4）您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。<br />
      </div>
      <div style="text-indent:2em">
        2、位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括<br />
      </div>
      <div style="text-indent:2em">
        1）您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；<br />
      </div>
      <div style="text-indent:2em">
        2）您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息；<br />
      </div>
      <div style="text-indent:2em">
        3）您可以通过关闭定位功能，停止对您的地理位置信息的收集。<br />
      </div>
      <div style="text-indent:2em">
        3. 信息披露<br />
      </div>
      <div style="text-indent:2em">
        在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：<br />
      </div>
      <div style="text-indent:2em">
        (a)未经您事先同意，我们不会向第三方披露；<br />
      </div>
      <div style="text-indent:2em">
        (b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；<br />
      </div>
      <div style="text-indent:2em">
        (c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；<br />
      </div>
      <div style="text-indent:2em">
        (d)如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；<br />
      </div>
      <div style="text-indent:2em">
        (e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；<br />
      </div>
      <div style="text-indent:2em">
        4. 信息存储和交换<br />
      </div>
      <div style="text-indent:2em">
        本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。<br />
      </div>
      <div style="text-indent:2em">
        5. 第三方SDK类服务商<br />
      </div>
      <div style="text-indent:2em">
        为了向您提供更好的服务，我公司的产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。目前我公司接入的第三方SDK如下：<br />
      </div>
      <div style="text-indent:2em">
        （1）腾讯:<br />
      </div>
      <div style="text-indent:2em">
        场景描述：用于通过微信模块快速登录，用于车主缴费时选择使用微信支付；<br />
      </div>
      <div style="text-indent:2em">
        使用目的：为用户提供停车的费用支付。<br />
      </div>
      <div style="text-indent:2em">
        第三方SDK公司名称：深圳市腾讯计算机系统有限公司。<br />
      </div>
      <div style="text-indent:2em">
        （3）高德地图:<br />
      </div>
      <div style="text-indent:2em">
        场景描述：手机中车场位置显示定位。<br />
      </div>
      <div style="text-indent:2em">
        使用目的：提供定位，车场查询等地图相关功能。<br />
      </div>
      <div style="text-indent:2em">
        第三方SDK公司名称：高德软件有限公司。<br />
      </div>
      <div style="text-indent:2em">
        （4）阿里:<br />
      </div>
      <div style="text-indent:2em">
        场景描述：用于车主缴费时选择使用银联提供的支付宝支付。<br />
      </div>
      <div style="text-indent:2em">
        使用目的：为用户提供停车费用支付。<br />
      </div>
      <div style="text-indent:2em">
        第三方SDK公司名称：支付宝（杭州）信息技术有限公司。<br />
      </div>
      <div style="text-indent:2em">
        6. Cookie的使用<br />
      </div>
      <div style="text-indent:2em">
        (a)在您未拒绝接受cookies的情况下，本应用会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。<br />
      </div>
      <div style="text-indent:2em">
        (b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。<br />
      </div>
      <div style="text-indent:2em">
        (c)通过本应用所设cookies所取得的有关信息，将适用本政策。<br />
      </div>
      <div style="text-indent:2em">
        7. 本隐私政策的更改<br />
      </div>
      <div style="text-indent:2em">
        (a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。<br />
      </div>
      <div style="text-indent:2em">
        (b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！<br />
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>
